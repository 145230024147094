import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const NoFightProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              No Fight Tights.<br />
              Fashionable & Functional.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              After many struggles getting her daughter ready for school in the morning and the stress associated with watching her daughter fight to feel comfortable in her school tights, It was identified that there has to be a better solution. No Fight Tights Founder and Mum Corinna Preston got to work to design and create the perfect pair of tights to solve all those early morning struggles in many households.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              "I had the idea of combining something along the lines of tights/leggings. So off I went to buy a pair of girls leggings and school tights, I had a friend combine the two and VOILA! – No Fight Tight’s (NFT’s) was born. As a runner I was aware of how comfortable running tights can be so I knew this design could be further improved. NFT’s unique patented design now combines a lycra upper with a traditional cotton weave lower portion that meets school uniform requirements but can also be worn as a fabulous sport or fashion accessory."
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              After initially liaising with a local sample room and manufacturer, Corinna was looking for a partner to facilitate her complete end-to-end mass manufacturing & procurement process to bring her dream product to life - so luckily she found Sourci!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              "I Couldn’t Have Made My Dream Come True Without Your Team"
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here’s how we made amazing happen for No Fight Tights.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/no-fight-tights-case1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>6</span>
            </p>
            <p className="font-black text-xl">PRODUCT RANGE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;2.5%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>210+</span>
            </p>
            <p className="font-black text-xl">HOURS OF WORK</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>12M</span>
            </p>
            <p className="font-black text-xl">WARRANTY</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/no-fight-tights-case2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <p className="text-xl lg:text-2xl mb-8">
              We helped Corinna navigate a complex product development process with multiple prototypes and exceptional problem solving required to ensure the product serves its intended process of comfortability, durability and quality in this unique proprietary product.
            </p>
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              The Detail Is Everything
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              We identified multiple specialist garment manufacturers to ensure we were able to cross cost to present the best price possible. With wholesale dreams in mind, negotiation of price was a priority to ensure a profitable venture for all.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We worked closely with the manufacturers and product development teams to troubleshoot many challenges around prototyping, ensuring that there was confidence and excitement around the finished product moving into production.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Implementing sales contracts on the terms of engagement with the factory set the ground rules and arranging quality control checks upon production completion ensured the highest of quality in goods leaving the factory, minimising defect rates and ensuring full visibility of product quality & packing prior to shipment.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The No Fight Tights brand is now scaling fast, with regional Victoria backing this home-grown business, being stocked in half a dozen school uniform stores and quickly becoming a household name for school girls & mums across the state.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              They are being featured in various media and publications, and we are confident that it won’t be long before we’ll be seeing the brilliant NFT brand all across the country soon!
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Push the button.
            <span className="block text-sourci-pink">Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - No Fight Tights" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="no-fight-tights"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default NoFightProject;

export function Head() {
  return (
    <Seo title="No Fight Tights| Our Projects | Sourci"
      description="Are you a startup or an ecommerce business? Check out some recent projects that we've been working on with our incredible brands!"
      canonical="/projects/no-fight-tights" />
  )
}